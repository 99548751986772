<template>
  <!-- eslint-disable -->
  <div class="contact-us-form-container">
    <template v-for="fieldConfig in fields">
      <div class="field-container full-name-field" v-if="fieldConfig.name === fieldNames.fullname">
        <input
          v-model="firstName"
          placeholder="First Name"
          id="firstname"
          :disabled="fieldConfig.disabled"
          @keyup.enter="submitClick"
        />
        <input
          v-model="lastName"
          placeholder="Last Name"
          id="lastname"
          :disabled="fieldConfig.disabled"
          @keyup.enter="submitClick"
        />
      </div>
      <div class="field-container email-field" v-if="fieldConfig.name === fieldNames.email">
        <div class="label" v-if="fieldConfig.label">{{ fieldConfig.label }}</div>
        <input
          v-model="email"
          placeholder="Email Address"
          id="email"
          type="email"
          ref="email"
          class="email-input"
          :class="{'has-error': errors && errors.email}"
          :disabled="fieldConfig.disabled"
          @keyup.enter="submitClick"
          @change="changeEmail"
        />
        <transition name="fade">
          <p class="error" v-if="errors.email">{{ errors.email }}</p>
        </transition>
      </div>

      <div class="field-container interests-field" v-if="fieldConfig.name === fieldNames.interests">
        <div class="label">I'd like to...</div>
        <div class="interests-container">
          <div class="interests-column">
            <div v-for="option in interestsOptions1" :key="option.value" class="interest">
              <input
                type="checkbox"
                :id="option.value"
                :value="option.value"
                :checked="isChecked(option.value)"
                @change="changeInterests"
              />
              <label :for="option.value">{{ option.label }}</label>
            </div>
          </div>
          <div class="interests-column">
            <div v-for="option in interestsOptions2" :key="option.value" class="interest">
              <input
                type="checkbox"
                :id="option.value"
                :value="option.value"
                :checked="isChecked(option.value)"
                @change="changeInterests"
              />
              <label :for="option.value">{{ option.label }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="field-container no-margin" v-if="fieldConfig.name === fieldNames.comments">
        <div class="label" v-if="fieldConfig.label">{{ fieldConfig.label }}</div>
        <textarea id="comments" v-model="otherComment" :placeholder="fieldConfig.placeholder"></textarea>
      </div>

      <div v-if="fieldConfig.name === fieldNames.uploadFiles" class="field-container upload-container">
        <a @click="selectFiles" class="attach-files-link">+ Attach Files</a>
        <input type="file" multiple class="files-input" :ref="filesInputRef" @change="fileInputChange" />

        <transition-group name="fade">
          <div v-for="(attachment, index) in attachments" :key="getAttachmentId(attachment, index)" class="attachment">
            <div class="attachment-name">{{ attachment.name }} ({{ getDisplaySize(attachment.size) }})</div>
            <a v-if="!loading" class="delete" @click="deleteAttachment(index)">Delete</a>
          </div>
        </transition-group>
      </div>

      <ancestor-field-set
        v-if="fieldConfig.name === fieldNames.ancestorFields"
        :relation="contactUsFormAncestorState.relation"
        :last-name="contactUsFormAncestorState.lastName"
        :last-name-disabled="fieldConfig.lastNameDisabled"
        :errors="errors.ancestor"
        @input-relation="inputAncestorRelation"
        @input-first-name="inputAncestorFirstName"
        @input-last-name="inputAncestorLastName"
      ></ancestor-field-set>

      <input-field
        v-if="fieldConfig.name === fieldNames.input"
        :placeholder="fieldConfig.placeholder"
        :ref="fieldConfig.ref"
        :id="fieldConfig.ref"
      ></input-field>
    </template>

    <div class="field-container" v-if="!userIsLoggedInState && hasSubscribeOption">
      <div>
        <input type="checkbox" id="subscribe" v-model="subscribeToNewsletter" @keyup.enter="submitClick" />
        <label for="subscribe">Email me search tips and match updates</label>
      </div>
    </div>

    <mcr-button class="submit-button" @click="submitClick" :disabled="loading">
      <span v-if="loading" class="upload-status">Submitting Request... {{ contactUsFormLoadingProgressState }}%</span>
      <span v-else>{{ buttonLabel }}</span>
    </mcr-button>
  </div>
</template>
<script>
/* eslint-disable */
import {PR_ENTRY_POINT_URL_NAME} from '@/store/modules/contact-us-form/constants';
import McrButton from '@common/elements/buttons/mcrButton';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import AnalyticsHandler from '@common/utils/analytics/analytics.handler';
import AnalyticsHubspotHandler from '@common/utils/analytics/analytics.hubspot';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {isEmailValid, splitByFirstSpace} from '@common/utils/utils';
import isEmpty from 'lodash/isEmpty';
import {mapGetters, mapMutations} from 'vuex';

import consts from '@/base/utils/consts';

import AncestorFieldSet from '@/components/modules/contactUs/fields/AncestorFieldSet';
import InputField from '@/components/modules/contactUs/fields/InputField';

import {FIELD_NAMES, FORM_TYPES} from './constants';

export default {
  created() {
    this.$store.commit('cleanupContactUsFormState');
    const email =
      this.type === FORM_TYPES.bookcall ? this.$route.query.invitee_email : this.$store.getters.userEmailState;
    let firstName = this.$store.getters.userFirstNameState;
    let lastName = this.$store.getters.userLastNameState;
    if (this.$route.query.invitee_full_name) {
      const [inviteeFirstName, inviteeLastName] = splitByFirstSpace(this.$route.query.invitee_full_name);
      firstName = inviteeFirstName || firstName || '';
      lastName = inviteeLastName || lastName || '';
    }
    this.$store.commit('mutateContactUsFormState', {email, firstName, lastName});

    if (!this.hasSubscribeOption) {
      this.$store.commit('mutateContactUsFormState', {subscribeToNewsletter: false});
    }

    if (this.type === FORM_TYPES.bookcall && this.$route.query.event_start_time) {
      this.send(this.$route.query.event_start_time);
      this.$router.push({query: {type: FORM_TYPES.bookcall}});
    }
    this.initForm();
  },
  props: {
    type: {type: String, validator: val => Object.values(FORM_TYPES).includes(val)},
    initData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    submitUrl: {type: String, default: null},
    isPayment: {type: Boolean, default: false},
  },
  watch: {
    initData(newValue) {
      this.initForm();
    },
  },
  data() {
    const prevRoute = localStorage.getItem(PR_ENTRY_POINT_URL_NAME);
    localStorage.removeItem(PR_ENTRY_POINT_URL_NAME);
    const interestsOptions1 = [
      {value: 'understand-history', label: 'Understand my history'},
      {value: 'find-records', label: 'Find records'},
      {value: 'translate-records', label: 'Translate records'},
    ];
    const interestsOptions2 = [
      {value: 'plan-travel', label: 'Plan roots travel'},
      {value: 'connect', label: 'Connect with relatives'},
      {value: 'research-adoptees', label: 'Research adoptees'},
    ];
    return {
      errors: {},
      loading: false,
      filesInputRef: 'files-input',
      maxFileSizeMB: consts.maxFileSizeMB,
      maxFileSize: consts.maxFileSizeMB * 1024 * 1024,
      interestsOptions1: interestsOptions1,
      interestsOptions2: interestsOptions2,
      interestsOptions: [...interestsOptions1, ...interestsOptions2],
      fieldNames: FIELD_NAMES,
      prevRoute: prevRoute,
    };
  },
  computed: {
    ...mapGetters([
      'contactUsFormState',
      'contactUsFormLoadingProgressState',
      'userIsLoggedInState',
      'contactUsFormIdState',
      'contactUsFormAncestorState',
      'contactUsFormAdditionalInfoState',
    ]),
    firstName: {
      get() {
        return this.contactUsFormState.firstName;
      },
      set(value) {
        this.$store.commit('mutateContactUsFormState', {firstName: value.trim()});
      },
    },
    lastName: {
      get() {
        return this.contactUsFormState.lastName;
      },
      set(value) {
        this.$store.commit('mutateContactUsFormState', {lastName: value.trim()});
      },
    },
    email: {
      get() {
        return this.contactUsFormState.email;
      },
      set(value) {
        this.$store.commit('mutateContactUsFormState', {email: value.trim()});
      },
    },
    otherComment: {
      get() {
        return this.contactUsFormState.otherComment;
      },
      set(value) {
        this.$store.commit('mutateContactUsFormState', {otherComment: value.trim()});
      },
    },
    hasSubscribeOption() {
      const types = [
        FORM_TYPES.simple,
        FORM_TYPES.ancestor,
        FORM_TYPES.tshirtwaitlist,
        FORM_TYPES.tshirtwaitlistUpdate,
      ];
      return !types.includes(this.type);
    },
    attachments: {
      get() {
        return this.contactUsFormState.attachments;
      },
      set(value) {
        this.$store.commit('mutateContactUsFormState', {attachments: value});
      },
    },
    subscribeToNewsletter: {
      get() {
        return this.contactUsFormState.subscribeToNewsletter;
      },
      set(value) {
        this.$store.commit('mutateContactUsFormState', {subscribeToNewsletter: value});
      },
    },
    emailErrorText() {
      return 'Please enter a valid email address';
    },
    fieldRequiredErrorText() {
      return 'This field is required';
    },
    fieldsByType() {
      return {
        [FORM_TYPES.chat]: [
          {name: FIELD_NAMES.email, label: 'Your details'},
          {
            name: FIELD_NAMES.comments,
            placeholder: 'Comments',
            label:
              "Please include your surname, Chinese ancestor's name, and where in China they were from. Preferably in Chinese characters!",
          },
          {name: FIELD_NAMES.uploadFiles},
        ],
        [FORM_TYPES.simple]: [{name: FIELD_NAMES.email}],
        [FORM_TYPES.bookcall]: [
          {name: FIELD_NAMES.fullname, disabled: this.firstName || this.lastName},
          {name: FIELD_NAMES.email, disabled: this.email},
          {name: FIELD_NAMES.interests},
          {name: FIELD_NAMES.comments, placeholder: 'Comments'},
          {name: FIELD_NAMES.uploadFiles},
        ],
        [FORM_TYPES.ancestor]: [
          {name: FIELD_NAMES.email},
          {name: FIELD_NAMES.ancestorFields, lastNameDisabled: true, firstNameRequired: false, relationRequired: true},
        ],
        [FORM_TYPES.ancestortranslation]: [
          {name: FIELD_NAMES.email},
          {name: FIELD_NAMES.ancestorFields, lastNameDisabled: false, firstNameRequired: true, relationRequired: false},
          {name: FIELD_NAMES.input, ref: 'ancestral-place', placeholder: 'Ancestral place'},
          {name: FIELD_NAMES.input, ref: 'dialects', placeholder: 'Dialect(s) spoken'},
          {name: FIELD_NAMES.comments, placeholder: 'Migration history'},
          {name: FIELD_NAMES.uploadFiles},
        ],
        [FORM_TYPES.tshirtwaitlist]: [{name: FIELD_NAMES.email}],
      };
    },
    fields() {
      const defaultFields = [
        {name: FIELD_NAMES.fullname},
        {name: FIELD_NAMES.email},
        {name: FIELD_NAMES.interests},
        {name: FIELD_NAMES.comments, placeholder: 'Comments'},
        {name: FIELD_NAMES.uploadFiles},
      ];
      return this.fieldsByType[this.type] || defaultFields;
    },
    buttonLabel() {
      const mapping = {
        [FORM_TYPES.bookcall]: 'Submit',
        [FORM_TYPES.ancestortranslation]: 'Submit',
        [FORM_TYPES.ancestor]: 'Ask our researchers',
        [FORM_TYPES.tshirtwaitlist]: 'Join waitlist',
      };
      return mapping[this.type] || 'Get in touch';
    },
  },
  methods: {
    ...mapMutations(['mutateContactUsFormAncestorState', 'mutateContactUsFormAdditionalInfoState']),
    initForm() {
      // toDo: remove ancestor state and use additional info instead
      if (isEmpty(this.initData)) {
        this.mutateContactUsFormAdditionalInfoState({});
      }
      const {ancestor, additionalInfo, ...data} = this.initData;
      this.mutateContactUsFormAncestorState(ancestor || {});
      this.mutateContactUsFormAdditionalInfoState(additionalInfo || {});
      this.$store.commit('mutateContactUsFormState', data);
    },
    send(callDatetime) {
      if (this.formInvalid()) {
        this.$nextTick(() => {
          // next tick for error to appear on form
          this.$emit('form-invalid');
        });
        return Promise.reject();
      }
      const interestsList = this.contactUsFormState.interests.map(
        interest => this.interestsOptions.find(option => option.value === interest).label
      );
      const interests = interestsList.join(', ');
      const referrer =
        document.referrer === this.$route.fullPath || document.referrer === document.URL ? null : document.referrer;
      const submitUrl = this.submitUrl;
      const formType = this.type || 'default';
      const vueEntryPoint = submitUrl || this.prevRoute;
      const prEntryPointUrl = vueEntryPoint || referrer || 'direct';
      const prEntryPointName = this.getPrEntryPointName(vueEntryPoint, referrer);

      return new Promise((resolve, reject) => {
        this.loading = true;
        AnalyticsHubspotHandler.identify(this.contactUsFormState.email);
        const params = {
          ...this.contactUsFormState,
          callDatetime,
          interests,
          formType,
          prEntryPointUrl,
          prEntryPointName,
          isPayment: this.isPayment,
          otherComment: this.getComment(),
          additionalInfo: this.getAdditionalInfo(),
        };
        const hubspotParams = {
          email: params.email,
          form_submission_page_path: prEntryPointName,
          interests: interestsList.join(';'),
          message: params.otherComment,
          firstname: params.firstName,
          lastname: params.lastName,
        };
        const hubspotPromise = AnalyticsHubspotHandler.submitFormContactUs(
          hubspotParams,
          window.location.href,
          getRoutePageName(this.$route)
        );
        const backendPromise = this.$store.dispatch('sendContactUsFormAction', params).then(response => {
          if (!this.userIsLoggedInState) {
            AnalyticsAmplitudeHandler.setUserId(this.contactUsFormState.email); // set user id so BE event is tracked to same profile
          }
          AnalyticsAmplitudeHandler.setUserFullNameProperties(
            this.contactUsFormState.firstName,
            this.contactUsFormState.lastName
          );
          sessionStorage.setItem('contactEmail', this.contactUsFormState.email);
          return response;
        });
        Promise.all([hubspotPromise, backendPromise]).then(([hubspotRes, backendRes]) => {
          this.loading = false;
          resolve(backendRes);
        });
      });
    },
    sendUpdate() {
      return this.$store.dispatch('updateContactUsFormAction', {
        id: this.contactUsFormIdState,
        ...this.contactUsFormState,
      });
    },
    submitClick() {
      if (this.loading) {
        return;
      }
      const submit = this.contactUsFormIdState ? this.sendUpdate : this.send;
      submit().then(response => {
        this.$emit('submit', {buttonLabel: this.buttonLabel, response});
        AnalyticsHandler.trackContactSubmittedEvent(this.type);
      });
    },
    changeEmail() {
      const emailError = isEmailValid(this.email) ? '' : this.emailErrorText;
      this.errors = {...this.errors, email: emailError};
    },
    changeInterests(value) {
      const interests = value.target.checked
        ? [...this.contactUsFormState.interests, value.target.value]
        : this.contactUsFormState.interests.filter(item => item !== value.target.value);
      this.$store.commit('mutateContactUsFormState', {interests});
    },
    deleteAttachment(index) {
      this.attachments.splice(index, 1);
    },
    selectFiles() {
      this.$refs[this.filesInputRef][0].click();
    },
    fileInputChange(event) {
      this.filesSelected(event.target.files);
    },
    filesSelected(files) {
      if (!files.length) {
        return;
      }
      let validFiles = [];
      let hasInvalidFile = false;
      for (let file of files) {
        file.size <= this.maxFileSize ? validFiles.push(file) : (hasInvalidFile = true);
      }
      if (hasInvalidFile) {
        this.handleFilesSelectError(`File max size: ${this.maxFileSizeMB}MB`);
      }
      this.attachments.push(...validFiles);
    },
    handleFilesSelectError(errorText) {
      this.$toasted.error(errorText);
    },
    getDisplaySize(fileSize) {
      if (!fileSize) {
        return '';
      }
      fileSize = Math.floor(fileSize / 1024);
      return fileSize < 1024 ? `${fileSize}KB` : `${Math.floor(fileSize / 1024)}MB`;
    },
    getAttachmentId(attachment, index) {
      return `${attachment}-${index}`;
    },
    isChecked(value) {
      return this.contactUsFormState.interests.includes(value);
    },
    formInvalid() {
      this.errors = {};
      if (!isEmailValid(this.email)) {
        this.errors.email = this.emailErrorText;
      }
      this.validateAncestorFields();
      return !isEmpty(this.errors);
    },
    validateAncestorFields() {
      const ancestorConfig = this.fields.find(item => item.name === FIELD_NAMES.ancestorFields);
      if (!ancestorConfig) {
        return;
      }
      if (ancestorConfig.relationRequired && !this.contactUsFormAncestorState.relation) {
        this.errors.ancestor = {...(this.errors.ancestor || {}), relation: this.fieldRequiredErrorText};
      }
      if (!this.contactUsFormAncestorState.lastName) {
        this.errors.ancestor = {...(this.errors.ancestor || {}), lastName: this.fieldRequiredErrorText};
      }
      if (ancestorConfig.firstNameRequired && !this.contactUsFormAncestorState.firstName) {
        this.errors.ancestor = {...(this.errors.ancestor || {}), firstName: this.fieldRequiredErrorText};
      }
    },
    getAdditionalInfo() {
      const mapping = {
        [FORM_TYPES.ancestor]: this.contactUsFormAncestorState,
      };
      return mapping[this.type] || this.contactUsFormAdditionalInfoState;
    },
    getComment() {
      const mapping = {
        [FORM_TYPES.ancestor]: this.getCommentAncestorForm,
        [FORM_TYPES.ancestortranslation]: this.getCommentAncestorTranslationForm,
      };
      const method = mapping[this.type];
      return method ? method() : this.contactUsFormState.otherComment;
    },
    getCommentAncestorForm() {
      const {relation, firstName, lastName} = this.contactUsFormAncestorState;
      return `I am curious about my ${relation}.\nFirst Name: ${firstName}\nLast Name: ${lastName}`;
    },
    getCommentAncestorTranslationForm() {
      const ancestorFormComment = this.getCommentAncestorForm();
      const fieldsComments = [];

      for (let field of this.fields) {
        if (field.ref) {
          const value = this.$refs[field.ref][0].$refs.input.value;
          fieldsComments.push(`${field.placeholder}: ${value}`);
        }
      }
      const comments = `Migration history: ${this.contactUsFormState.otherComment}`;
      return `${ancestorFormComment}\n${fieldsComments.join('\n')}\n${comments}`;
    },
    inputAncestorRelation(relation) {
      this.mutateContactUsFormAncestorState({relation});
    },
    inputAncestorFirstName(firstName) {
      this.mutateContactUsFormAncestorState({firstName});
    },
    inputAncestorLastName(lastName) {
      this.mutateContactUsFormAncestorState({lastName});
    },
    getPrEntryPointName(route, referrer) {
      if (route) {
        try {
          return getRoutePageName(this.$router.match(route));
        } catch (e) {
          return '';
        }
      }
      if (referrer && referrer.includes('mychinaroots.com/blog')) {
        return 'blog';
      }
      return 'other';
    },
  },
  components: {InputField, AncestorFieldSet, McrButton},
};
</script>
<style lang="scss" scoped>
.contact-us-form-container {
  .field-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    &.no-margin {
      margin-bottom: 0;
    }

    .label {
      margin-bottom: 5px;
      font-weight: bold;
    }

    .field-label {
    }

    .error {
      margin-bottom: 0;
    }
  }

  #comments {
    height: calc(4em + 3px);
    width: 100%;
    box-sizing: border-box;
  }

  .full-name-field {
    input:first-child {
      margin-bottom: 20px;
    }

    @media only screen and (min-width: $breakpoint-mobile) {
      flex-direction: row;

      input:first-child {
        margin: 0 20px 0 0;
      }
      input {
        width: 50%;
      }
    }
  }

  .email-field {
    margin-top: 15px;
    .email-input {
      margin-top: 2px;
      width: 100%;
      box-sizing: border-box;
      -webkit-appearance: none;
    }
  }

  .interests-field {
    margin-top: 36px;
    .interests-container {
      padding: 8px 0;
      display: flex;
      flex-direction: row;
      .interests-column {
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 14px;
      }
    }
    @media only screen and (max-width: $breakpoint-mobile) {
      .interests-container {
        flex-direction: column;
        row-gap: 14px;
      }
    }
  }

  .upload-container {
    margin-top: 10px;
    margin-bottom: 8px;
    .attach-files-link {
      display: block;
      text-align: right;
      color: $mcr-tan;
    }
    .attachment {
      border-bottom: 1px solid $divider-line-light;
      padding: 8px 0;
      &:first-of-type {
        border-top: 1px solid $divider-line-light;
        margin-top: 8px;
      }
      &:last-of-type {
        margin-bottom: 16px;
      }
      display: flex;
      justify-content: space-between;
      margin: 5px 0;

      .attachment-name {
        flex-grow: 1;
        padding-right: 16px;
        flex-wrap: wrap;
        word-break: break-all;
      }
    }
  }

  .mcr-button.disabled {
    background-color: $secondary-button-color;
    border-color: $secondary-button-color;
    .upload-status {
    }
  }
}

.files-input {
  display: none;
}
.mcr-button.disabled {
  background-color: $secondary-button-color;
  border-color: $secondary-button-color;
}
</style>
